<template>
  <section>
    <TopBanner title="Career Detail" :src="require('@/assets/images/banners/banner_career.jpg')" />
    <v-container class="content-container">
      <div id="careers_detail">
        <section v-if="jobPosting">
          <v-card
            tile
            class="mx-auto px-6 py-8"
            max-width="800"
          >
            <v-row>
              <v-col cols="8">
                <div class="text-h5 font-weight-bold">{{ jobPosting.subject }}</div>
                <div class="text-body-2 font-weight-light">{{ jobPosting.organization }} | {{ jobPosting.jobType }} | {{ jobPosting.city }}, {{ jobPosting.state }} {{ jobPosting.zip }}</div>
              </v-col>
              <v-col cols="4">
                <v-btn
                  :href="`mailto:${jobPosting.emailTo}?subject=Interested in ${jobPosting.jobTitle} position`"
                  elevation="0"
                  color="primary"
                  height="50"
                  block
                  tile
                >
                  Apply
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <br />
          <v-card
            class="mx-auto px-6 py-8"
            max-width="800"
            tile
          >
            <span v-html="jobPosting.description"></span>
            <v-chip
              v-for="tag in jobPosting.tags.trim().split(',')"
              :key="`career-tag-${jobPosting.jobPostingId}-${tag}`"
              class="mr-1"
              color="lightgrey"
              label
            >
              {{ tag }}
            </v-chip>
          </v-card>
        </section>
        <section v-else>
          ...loading
        </section>
      </div>
    </v-container>
    <Footer />
  </section>
</template>

<script>
import TopBanner from "@/components/TopBanner";
import Footer from "@/views/layouts/Footer";
import axios from "axios";

export default {
  components: {
    TopBanner,
    Footer
  },
  async created(){
    this.jobPosting = await this.getJobPostings(this.postingId);
    console.log(this.jobPosting);
  },
  data(){
    return {
      postingId: this.$route.query.postingId,
      jobPosting: null
    };
  },
  methods: {
    async getJobPostings(postingId){
      var res = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/Feature/Public/JobPostings?postingId=${postingId}`,
        {
          headers: {
            Authorization: `Basic ${btoa(this.$store.state.basicAuth.userName + ':' + this.$store.state.basicAuth.password)}`,
          },
        })
        .then(resp => {
          return resp.data;
        })
        .catch(() => {
          return null;
        });

      return res;
    }
  }
}
</script>